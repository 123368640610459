body {
    margin: 0;
}

html {
    /* width */
    ::-webkit-scrollbar {
        width: 1px;
        height: 1px;
        opacity: 0.5%;
    }

    /* button */
    ::-webkit-scrollbar-button {
        background: #3f3f3f;
        opacity: 0.5%;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #474747;
        opacity: 0.5%;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #666666;
        opacity: 0.5%;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #3f3f3f;
        opacity: 0.5%;
    }

    /* Corner */
    ::-webkit-scrollbar-corner {
        background: #3f3f3f;
        opacity: 0.5%;
    }

    /* Resizer */
    ::-webkit-resizer {
        background: #111;
        opacity: 0.5%;
    }
}
