$length: 0.35rem;

@mixin dark {
    /* width */
    ::-webkit-scrollbar {
        @media screen and (max-width: 960px) {
            width: $length;
            height: $length;
        }
        @media screen and (min-width: 960px) {
            width: 0.5rem;
            height: 0.5rem;
        }
    }

    /* button */
    ::-webkit-scrollbar-button {
        background: #3f3f3f;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #474747;
        opacity: 0.5%;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #666666;
        opacity: 0.5%;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #3f3f3f;
    }

    /* Corner */
    ::-webkit-scrollbar-corner {
        background: #3f3f3f;
    }

    /* Resizer */
    ::-webkit-resizer {
        background: #111;
    }
}

@mixin light {
    /* width */
    ::-webkit-scrollbar {
        @media screen and (max-width: 960px) {
            width: $length;
            height: $length;
        }
        @media screen and (min-width: 960px) {
            width: 0.5rem;
            height: 0.5rem;
        }
    }

    /* button */
    ::-webkit-scrollbar-button {
        background: #d3d7e3;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #d6d6d6;
        border-radius: 25%;
        opacity: 0.5%;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #1565c0;
        opacity: 0.5%;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #e9ebf1;
    }

    /* Corner */
    ::-webkit-scrollbar-corner {
        background: #e9ebf1;
    }

    /* Resizer */
    ::-webkit-resizer {
        background: #d3d7e3;
    }
}

@mixin tan {
    /* width */
    ::-webkit-scrollbar {
        width: $length;
        height: $length;
    }

    /* button */
    ::-webkit-scrollbar-button {
        background: #262626;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #222222;
        opacity: 0.5%;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #353535;
        opacity: 0.5%;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #131313;
    }

    /* Corner */
    ::-webkit-scrollbar-corner {
        background: #0b0b0b;
    }

    /* Resizer */
    ::-webkit-resizer {
        background: #000000;
    }
}

@mixin blue {
    /* width */
    ::-webkit-scrollbar {
        @media screen and (max-width: 960px) {
            width: $length;
            height: $length;
        }
        @media screen and (min-width: 960px) {
            width: 0.5rem;
            height: 0.5rem;
        }
    }

    /* button */
    ::-webkit-scrollbar-button {
        background: #00077a;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #0d47a1;
        opacity: 0.5%;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #1565c0;
        opacity: 0.5%;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #003340;
    }

    /* Corner */
    ::-webkit-scrollbar-corner {
        background: #000ad2;
    }

    /* Resizer */
    ::-webkit-resizer {
        background: #2157f5;
    }
}

@mixin nba {
    /* width */
    ::-webkit-scrollbar {
        @media screen and (max-width: 960px) {
            width: $length;
            height: $length;
        }
        @media screen and (min-width: 960px) {
            width: 0.5rem;
            height: 0.5rem;
        }
    }

    /* button */
    ::-webkit-scrollbar-button {
        background: #00077a;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #8a0000;
        opacity: 0.5%;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #732424;
        opacity: 0.5%;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #003340;
    }

    /* Corner */
    ::-webkit-scrollbar-corner {
        background: #d20000;
    }

    /* Resizer */
    ::-webkit-resizer {
        background: #a20000;
    }
}

.dark-background {
    background-color: #2b2b2b;
    @include dark;
}

.light-background {
    background-color: #f4f5f8;
    @include light;
}

.blue-background {
    background-color: red;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    @include blue;
}

.tan-background {
    background-color: #d7bb99;
    @include tan;
}

.tan_dark-background {
    background-color: #111111;
    @include tan;
}

.classic_dark-background {
    background-color: #2b2b2b;
    @include dark;
}

.red_blue-background {
    background-color: #2b2b2b;
    @include dark;
}

.red_blue_alt-background {
    background-color: #2b2b2b;
    @include dark;
}

.star_wars-background {
    background-image: url("star_wars2.png");
    @include tan;
}

.mechwarrior_a_team-background {
    background-size: cover;
    background: #a8271a url("red desert.png") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    @include dark;
}

.mechwarrior_b_team-background {
    background-size: cover;
    background: #a8271a url("red desert.png") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    @include dark;
}

.NBA-background {
    background-image: url("hardwood.jpg");
    background-repeat: repeat;
    @include nba;
}
